import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogSeptember24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        Latest Release - September 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        September release notes summarizes latest changes for Service Info API, Equipment Status API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>Improvement in sandbox response for Service Info Webhook API.</li>
          </UL>
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              The <b>previousState</b> field is now optional for{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#availability-event'}>
                {' '}
                Equipment Availability Information Webhook API
              </Link>
              .
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
